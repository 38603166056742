import React, { useRef } from 'react';
import { Box, Container } from '@mui/material';
import { MRT_TableInstance } from 'material-react-table';
import { useParams } from 'react-router';
import ReportsHeader from '../../../organisms/headers/Company/Reports/ReportsHeader';
import { useAppSelector } from '../../../hooks/redux';
import ReportsModal from '../../../organisms/modals/Company/Reports/ReportsModal';
import ReportsTable from '../../../organisms/tables/Company/Reports/ReportsTable';
import { useGetReportStructureQuery } from '../../../store/api/reportStructure';

const Reports = () => {
  const { id: clientId } = useParams();

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const isReportsModalOpen = useAppSelector(
    (state) => state.reportsModal.isOpen
  );

  const { data: { data = [] } = {}, isLoading } = useGetReportStructureQuery({
    params: { clientId: clientId },
  });

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  return (
    <Box
      sx={{
        height: 'calc(100vh - 106px - 66px)',
        overflowY: 'scroll',
      }}
    >
      {isReportsModalOpen && <ReportsModal tableData={data} />}
      <ReportsHeader
        onResetButtonClick={onResetButtonClick}
        total={data?.length}
      />
      <Container
        sx={{
          marginBottom: '50px',
        }}
      >
        <ReportsTable
          isLoading={isLoading}
          data={data}
          tableInstanceRef={tableInstanceRef}
        />
      </Container>
    </Box>
  );
};
export default Reports;
